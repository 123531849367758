@import "./styles/_var.scss";
@import "./reset.scss";

html,
body {
  // min-width: 1400px;
}

// table {
//   table-layout: fixed;
// }

.mr10 {
  margin-right: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
// magrin
.mgt10 { margin-top: 10px; }

.mgt20 { margin-top: 20px; }

.mgt30 { margin-top: 30px; }

.mgt40 { margin-top: 40px; }

.mgt50 { margin-top: 50px; }

.mgb10 { margin-bottom: 10px; }

.mgb20 { margin-bottom: 20px; }

.mgb30 { margin-bottom: 30px; }

.mgb40 { margin-bottom: 40px; }

.mgb50 { margin-bottom: 50px; }

.mgl10 { margin-left: 10px; }

.mgl20 { margin-left: 20px; }

.mgl30 { margin-left: 30px; }

.mgl40 { margin-left: 40px; }

.mgl50 { margin-left: 50px; }

.mgr10 { margin-right: 10px; }

.mgr20 { margin-right: 20px; }

.mgr30 { margin-right: 30px; }

.mgr40 { margin-right: 40px; }

.mgr50 { margin-right: 50px; }

.link {
  color: #409eff;
}

.main-page-container {
  flex: 1;
  min-height: 200px;
  background: #fff;
  margin-top: 15px;
  padding: 20px;
  overflow: auto;
  .search-section {
    box-sizing: border-box;
    border-bottom: 1px solid #b5b8c9;
    padding-bottom: 20px;

    .ant-btn {
      margin-right: 10px;
    }
  }
}

.is-content-block {
  padding: 20px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  min-height: 500px;

  .title {
    h3 {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 20px;

      @extend .text-color-primary;
    }
  }
}

.is-module {
  width: 100%;

  @extend .border-one;

  .is-module-title {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px;
    background: #ededed;

    @extend .text-color-primary;
  }

  .is-module-content {
  }
}

.text-strong {
  font-weight: bold;
}

.text-color-common {
  color: #606266;
}

.text-color-primary {
  color: #303133;
}

.text-color-minor {
  color: $gray;
}

.text-color-placeholder {
  color: #c0c4cc;
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.color-gray {
  color: $gray;
}

.color-blue {
  color: $blue;
}

.border-one {
  border: 1px solid $border-color-one;
}

.border-one-left {
  border-left: 1px solid $border-color-one;
}

.border-one-right {
  border-right: 1px solid $border-color-one;
}

.border-one-top {
  border-top: 1px solid $border-color-one;
}

.border-one-bottom {
  border-bottom: 1px solid $border-color-one;
}

.border-two {
  border: 1px solid #e4e7ed;
}

.border-three {
  border: 1px solid #ebeef5;
}

.text-center {
  text-align: center;
}

.padding-15 {
  padding: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-normal {
  font-weight: normal;
}

html {
  height: 100%;
}

.basic-error-label {
  color: $red;
  font-size: 12px;
  line-height: 1;
  padding-top: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.layout-aside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  z-index: 55;
  background: #001529;
  overflow-y: auto;
  color: rgb(191, 203, 217);
  transition: 0.3s all;

  &.internal {
    background: #fff;

    .layout-aside-menus,
    .ant-menu {
      height: 100%;
    }
  }

  .layout-aside-logo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 30px 0;
    justify-content: center;

    img {
      margin-bottom: 20px;
      overflow: hidden;
    }

    span {
      display: inline-block;
      color: #fff;
      font-size: 18px;
      font-weight: normal;
    }
  }

  .layout-aside-logo-small {
    width: 100%;
    text-align: center;
    padding: 30px 0;

    img {
      width: 60%;
      border-radius: 50% 50%;
    }
  }

  .ant-layout-sider {
    height: 100%;

    .ant-layout-sider-children {
      overflow: auto;
    }

    .ant-layout-sider-trigger {
      position: absolute;
      bottom: 0;
      text-align: left;
      padding: 0 16px;
    }
  }

  .ant-layout-sider-collapsed .ant-layout-sider-trigger i {
    transform: rotate(180deg);
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 16px !important;
  }
}

.layout-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // min-width: 1000px;
  // display: block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 51;
  min-width: 1000px;
  transition: 0.3s margin-left;
  // padding-left: 200px;
}

.layout-header {
  min-width: 1000px;
  position: absolute;
  top: 0;
  left: 200px;
  right: 0;
  z-index: 50;
  background: #fff;
  padding: 0 50px 0 10px;
  transition: 0.3s all;

  > h3 {
    width: 100%;
    font-size: 18px;
    padding: 20px 0 5px;
  }

  .layout-header-breadcrumb {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
  }

  .layout-header-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    span {
      padding: 10px 0;
    }
  }
}

.layout-main {
  min-width: 1000px;
  transition: 0.3s margin-left;
  overflow: hidden;
  padding-top: 102px;
  display: block;
  z-index: 52;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: scroll;
  background: #f0f2f5;
  min-height: 100%;
  height: 100%;

  &.internal {
    padding-top: 40px;
  }
}

.ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.ant-btn-loading {
  // 兼容antd升级后按钮loading
  .anticon svg {
    animation: loadingCircle 1s infinite linear;
  }

  .ant-btn-loading-icon .anticon {
    animation: none;
  }
}
